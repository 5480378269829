.cbh-phone {
  position: fixed;
  right: 10px;
  bottom: 10px;
  visibility: hidden;
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 99;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
}

.cbh-phone {
  position: fixed;
  right: 10px;
  bottom: 10px;
  visibility: hidden;
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 99;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
}

#kmacb {
  position: fixed;
  display: none;
  background-color: transparent;
  width: 160px;
  height: 160px;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
  right: 15px;
  bottom: 15px;
}

.cbh-phone {
  position: fixed;
  right: -50px;
  bottom: -55px;
  visibility: hidden;
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 99;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
}
.cbh-phone.cbh-show {
  visibility: visible;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
.cbh-phone.cbh-static1 {
  opacity: 0.6;
}
.cbh-phone.cbh-hover1 {
  opacity: 1;
}
.cbh-ph-circle {
  width: 110px;
  height: 110px;
  top: 40px;
  left: 40px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  -webkit-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -o-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cbh-phone.cbh-active .cbh-ph-circle1 {
  -webkit-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  -moz-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  -ms-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  -o-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
}
.cbh-phone.cbh-static .cbh-ph-circle {
  -webkit-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -moz-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -ms-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -o-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
}
.cbh-phone.cbh-hover .cbh-ph-circle {
  border-color: rgba(0, 175, 242, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-green.cbh-hover .cbh-ph-circle {
  border-color: rgba(117, 235, 80, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-green .cbh-ph-circle {
  border-color: rgba(0, 175, 242, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-gray.cbh-hover .cbh-ph-circle {
  border-color: rgba(204, 204, 204, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-gray .cbh-ph-circle {
  border-color: rgba(117, 235, 80, 1);
  opacity: 0.5;
}
.cbh-ph-circle-fill {
  width: 74px;
  height: 74px;
  top: 58px;
  left: 58px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cbh-phone.cbh-active .cbh-ph-circle-fill {
  -webkit-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  -moz-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  -ms-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  -o-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
}
.cbh-phone.cbh-static .cbh-ph-circle-fill {
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important;
}
.cbh-phone.cbh-hover .cbh-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-green.cbh-hover .cbh-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-green .cbh-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-gray.cbh-hover .cbh-ph-circle-fill {
  background-color: rgba(204, 204, 204, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-gray .cbh-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 1 !important;
}
.cbh-ph-img-circle1 {
  width: 50px;
  height: 50px;
  top: 70px;
  left: 70px;
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==);
  background-size: 40px 40px;
  background-color: rgba(30, 30, 30, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 1;
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  animation: cbh-circle-img-anim 1s infinite ease-in-out;
}
.cbh-phone.cbh-active .cbh-ph-img-circle1 {
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
}
.cbh-phone.cbh-static .cbh-ph-img-circle1 {
  -webkit-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  -moz-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  -ms-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  -o-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
}
.cbh-phone.cbh-hover .cbh-ph-img-circle1 {
  background-color: rgba(0, 175, 242, 1);
}
.cbh-phone.cbh-green.cbh-hover .cbh-ph-img-circle1:hover {
  background-color: rgba(117, 235, 80, 1);
}
.cbh-phone.cbh-green .cbh-ph-img-circle1 {
  background-color: rgba(0, 175, 242, 1);
}
.cbh-phone.cbh-green .cbh-ph-img-circle1 {
  background-color: rgba(0, 175, 242, 1);
}
.cbh-phone.cbh-gray.cbh-hover .cbh-ph-img-circle1 {
  background-color: rgba(204, 204, 204, 1);
}
.cbh-phone.cbh-gray .cbh-ph-img-circle1 {
  background-color: rgba(117, 235, 80, 1);
}
@-moz-keyframes cbh-circle-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
    -moz-opacity: 0.1;
    -webkit-opacity: 0.1;
    -o-opacity: 0.1;
  }
  30% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    -o-opacity: 0.5;
  }
  100% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.6;
    -moz-opacity: 0.6;
    -webkit-opacity: 0.6;
    -o-opacity: 0.1;
  }
}
@-webkit-keyframes cbh-circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}
@-o-keyframes cbh-circle-anim {
  0% {
    -o-transform: rotate(0deg) kscale(0.5) skew(1deg);
    -o-opacity: 0.1;
  }
  30% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    -o-opacity: 0.5;
  }
  100% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    -o-opacity: 0.1;
  }
}
@keyframes cbh-circle-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
  }
}
@-moz-keyframes cbh-circle-fill-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -moz-transform: rotate(0deg) -moz-scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-webkit-keyframes cbh-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-o-keyframes cbh-circle-fill-anim {
  0% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@keyframes cbh-circle-fill-anim {
  0% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@keyframes cbh-circle-img-anim {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
}
@-moz-keyframes cbh-circle-img-anim {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
  }
}
@-webkit-keyframes cbh-circle-img-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
  }
}
@-o-keyframes cbh-circle-img-anim {
  0% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -o-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -o-transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
  }
}
.cbh-ph-img-circle1 {
}
.cbh-phone.cbh-green .cbh-ph-circle {
  border-color: rgb(113, 196, 102);
}
.cbh-phone.cbh-green .cbh-ph-circle-fill {
  background-color: rgb(113, 196, 102);
}
.cbh-phone.cbh-green .cbh-ph-img-circle1 {
  background-color: rgb(113, 196, 102);
}

.kmacb__manager-border {
  position: absolute;
  width: 75px;
  height: 75px;
  top: 50%;
  left: 50%;
  margin-top: -39.5px;
  margin-left: -39.5px;
  border-radius: 100%;
  border: 2px solid #ffe787;
  -webkit-animation: kmacb__manager-border-anim 1.5s ease-in-out 0.5s infinite;
  -moz-animation: kmacb__manager-border-anim 1.5s ease-in-out 0.5s infinite;
  -ms-animation: kmacb__manager-border-anim 1.5s ease-in-out 0.5s infinite;
  -o-animation: kmacb__manager-border-anim 1.5s ease-in-out 0.5s infinite;
  animation: kmacb__manager-border-anim 1.5s ease-in-out 0.5s infinite;
  opacity: 0.8;
  transform-origin: center;
}
.kmacb__manager-fill {
  background: #52aff7 center bottom no-repeat;
  position: absolute;
  width: 75px;
  height: 75px;
  top: 50%;
  left: 50%;
  margin-top: -37.5px;
  margin-left: -37.5px;
  border-radius: 100%;
  opacity: 0.5;
  -webkit-animation: kmacb__manager-fill-anim 1.5s ease-in-out infinite;
  -moz-animation: kmacb__manager-fill-anim 1.5s ease-in-out infinite;
  -ms-animation: kmacb__manager-fill-anim 1.5s ease-in-out infinite;
  -o-animation: kmacb__manager-fill-anim 1.5s ease-in-out infinite;
  animation: kmacb__manager-fill-anim 1.5s ease-in-out infinite;
  transform-origin: center;
}
.kmacb__manager-circle {
  background: #52aff7;
  position: absolute;
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;
  border-radius: 100%;
}