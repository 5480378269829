* {
  //font-family: "Montserrat", sans-serif !important;
}
//Navbar
#topnav {
  position: relative;
  z-index: 999 !important;
  margin-bottom: -74px;
}
@media (max-width: 991px) {
  #topnav {
    background-color: transparent;
  }
}

#topnav .navigation-menu > li {
  margin: 0 5px !important;
  white-space: nowrap;
}

.container {
  padding: 0 10px !important;
}

.app-logo {
  width: 140px;

  img {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 120px;
  }
  @media (max-width: 768px) {
    width: 100px;
    padding-bottom: 15px;
  }
}
.navigation-menu {
  li {
    a {
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }
}
@media (max-width: 991px) {
  .has-submenu {
    .menu-arrow {
      top: 50%;
      margin-top: -3px;
      right: 20px !important;
    }
  }
  #navigation {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
}

.app-login {
  .login-page {
    padding: 0px;
    @media (max-width: 991px) {
      padding: 50px;
    }
    @media (max-width: 768px) {
      padding: 0px !important;
    }
  }
  .app-login-body {
    overflow: auto;
    padding: 0px 0px;
  }
  .app-login-wrapper {
    @media (max-width: 768px) {
      height: 200px !important;
      .cover-user-img {
        align-items: start !important;
        height: auto !important;
      }
    }
  }
}
.app-nav-locale {
  @media (max-width: 768px) {
    position: absolute;
    left: 50px;
    bottom: 0px;
  }
}
.app-nav-locale-img {
  width: 25px;
  height: 25px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
}
.app-nav-btns {
  height: 74px;
  display: flex;
  align-items: center;
  margin-left: 5px;

  .app-nav-btn {
    position: relative;
    display: inline-block;
    height: auto;
    padding: 5px 15px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    border: none;
    margin: 5px;
    opacity: 0.8;
    font-weight: 400;
    text-align: center;

    &:hover {
      opacity: 1;
    }
    &.app-nav-btn-border {
      border: 1px dashed #fff;
    }
    .badge {
      position: absolute;
      right: -5px;
      bottom: -10px;
    }
  }
  @media (max-width: 991px) {
    .app-nav-btn {
      color: $primary;
      font-size: 12px;
      padding: 5px 5px;
      &.app-nav-btn-border {
        border: 1px dashed $primary;
      }
    }
  }
}
.nav-sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  z-index: 999;
  @media (min-width: 991px) {
    .app-nav-btn {
      color: $primary;
      &.app-nav-btn-border {
        border: 1px dashed $primary;
      }
    }
  }
}

.dropdown-item {
  font-size: 14px;
}

//btn
.btn {
  font-size: 14px;
  height: 36px;
  padding: 0px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
//form
.form-control {
  border-radius: 4px;
  height: 42px;
  &.ant-input-affix-wrapper-focused {
    border-color: #2f55d4;
    box-shadow: none;
  }
  input {
    background-color: transparent;
  }
}
.form-select {
  .ant-select-selector {
    height: 42px !important;
    border-radius: 4px !important;
    .ant-select-selection-item {
      padding-top: 6px;
    }
    .ant-select-selection-search {
      padding-top: 6px;
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      border-color: #2f55d4 !important;
      box-shadow: none !important;
    }
  }
}

//modal
.app-modal {
  .ant-modal-content {
    border-radius: 4px !important;
    .ant-modal-header {
      border-radius: 4px 4px 0 0 !important;
    }
    .ant-modal-footer {
      border-radius: 0 0 4px 4px !important;
    }
  }
}
//rounded
.rounded {
  border-radius: 4px !important;
}
//app-dropdown-menu
.app-dropdown-menu {
  background-color: #fff;

  min-width: 180px;
  padding: 15px 0;
  border: none;
  margin-top: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 3px #3c485826;
  .dropdown-item {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #3c4858;
    padding: 10px 20px;
    &:active {
      background-color: #fff;
    }
    &.app-active {
      color: $primary !important;
    }
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      right: 8px;
      box-sizing: border-box;
      border: 7px solid #495057;
      border-color: transparent transparent #ffffff #ffffff;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -2px 2px 2px -1px #3c485826;
    }
  }
}
//app-setting-collapse
.app-setting-collapse {
  border: none;
  background-color: transparent;
  .ant-collapse-item {
    background-color: #fff !important;
    border: none;
    margin-bottom: 30px;
    box-shadow: 0 0 0 0;
    box-shadow: $shadow;
    padding: 5px;
    .ant-collapse-header {
      color: #3c4858;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
    .ant-collapse-content {
      padding: 25px;
    }
  }
}

//
.breadcrumb-item {
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}
//Ant Messsage
.ant-message {
  .ant-message-custom-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 0;
  }
  .anticon {
    font-size: 18px;
  }
  span:not(.anticon) {
    margin-bottom: -2px;
  }
  .ant-message-success {
    .anticon {
      color: $success;
    }
  }
  .ant-message-error {
    .anticon {
      color: $danger;
    }
  }
  .ant-message-info {
    .anticon {
      color: $info;
    }
  }
  .ant-message-warning {
    .anticon {
      color: $warning;
    }
  }
  .ant-message-loading {
    .anticon {
      color: $primary;
    }
  }
}
//Ant Notifivation
.ant-notification {
  .ant-notification-notice-icon-success {
    .anticon {
      color: $success;
    }
  }
  .ant-notification-notice-icon-error {
    .anticon {
      color: $danger;
    }
  }
  .ant-notification-notice-icon-info {
    .anticon {
      color: $info;
    }
  }
  .ant-notification-notice-icon-warning {
    .anticon {
      color: $warning;
    }
  }
}

//Image Picker
.app-image-picker {
  position: relative;
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  padding: 6px;
  .app-image-picker-clear {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background-color: #f5f5f5;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    &:hover {
      color: red;
    }
    * {
      font-size: 18px;
    }
  }
  .app-image-picker-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
  }
  .app-image-picker-label {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: block;
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    &:hover {
      transform: scale(0.96);
      .app-image-picker-label-icon {
        color: #555555;
      }
    }
    .app-image-picker-label-icon {
      color: #999999;
      font-size: 30px;
    }
    .app-image-picker-label-input {
      position: absolute;
      transform: scale(0);
      opacity: 0;
    }
  }
  &.app-image-picker-transparent {
    background-color: transparent;
    .app-image-picker-label {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.app-locale-select {
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }
}

.text-decoration-line {
  text-decoration: line-through;
}

.app-tag {
  border: none !important;
  font-size: 10px;
  font-weight: 500;
  padding: 6px 10px;
  height: auto !important;
  border-radius: 4px;
  box-shadow: none;
  line-height: 1;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
}

[data-aos="fade-up"] {
  transform: translate3d(0, 30px, 0);
}

.app-pt {
  overflow: auto;
  @media (max-width: 991px) {
    overflow: visible !important;
  }
  width: 100%;
  .pt-tabbar {
    display: none !important;
    @media (max-width: 991px) {
      display: flex !important;
    }
    .pt-tabbar-item {
      cursor: pointer;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;

    .pt-props {
      min-width: 300px;
      @media (max-width: 991px) {
        min-width: 150px;
      }
    }
    thead {
      th {
        font-weight: 500;
        text-align: center;
        padding: 15px 10px;
      }
      th.pt-head {
        border: 1px solid #ddd;
        @media (max-width: 991px) {
          min-width: 150px;
        }
        .pt-md-hidden {
          @media (max-width: 991px) {
            display: none !important;
          }
        }
      }
    }
    tbody {
      td {
        padding: 20px;
        font-weight: 500;
        border: 1px solid #ddd;
        .pt-label {
          font-weight: 500;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }
      td.pt-value {
        text-align: center;
      }
    }
  }
}

.app-steps {
  .ant-steps-item::before {
    background-color: $primary !important;
  }
  .ant-steps-icon {
    color: $primary !important;
  }
}

.app-accounts-btn {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 25px 0;
  color: $primary;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: $shadow !important;

  &:hover {
    background-color: rgba($primary, 0.2);
    color: $primary !important;
  }
  &.active {
    color: $primary !important;
    border-color: $primary !important;
    background-color: rgba($primary, 0.2);
  }
}

.app-video-embed {
  width: 100%;
  height: 75vh;
  @media (max-width: 991px) {
    height: 320px;
  }
}

.app-checkout-form-frame {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  .app-checkout-form-frame-body {
    background-color: #fff;
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .app-checkout-form-frame-logo {
    display: inline-block;
    margin: 25px 0px;
    width: 200px;
  }
  .app-checkout-form-frame-close {
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 333;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    font-size: 32px;
  }
}

.ant-btn {
  font-weight: 500;
}

.top-bar {
  height: 70px;
  background-color: white;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}

.top-bar.open {
  height: 70px;
  background-color: #081d33;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}

.top-bar-button {
  font-weight: bold;
  width: 50%;
  padding: 15px;
  background-color: white;
  color: #081d33 !important;
  border: 0;
}

.top-bar-button.active {
  padding: 15px;
  background-color: #081d33;
  color: white !important;
  border: 0;
}

.shake-text {
  color: red;
  position: relative;
  bottom: 10px;
  right: 15px;
  animation: shake 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: rotate(35deg) translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: rotate(30deg) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: rotate(40deg) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: rotate(30deg) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: rotate(40deg) translate3d(4px, 0, 0);
  }
}
