//
// variables.scss
//

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Color
$white: #ffffff;

$primary: #3897cf; //2f55d4
$secondary: #071e33;
$success: #2eca8b;
$warning: #fed766;
$info: #17a2b8;
$danger: #fe4a49;
$dark: #3c4858;
$black: #161c2d;
$muted: #8492a6;
$light: #f8f9fc;

// Gray
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

// Overlay
$overlay: rgba($dark, 0.7);
$gradident-overlay: rgba($primary, 0.6);
$bg-overlay-white: rgba($white, 0.5);

//Body Background
$shadow: 0 0 3px rgba($dark, 0.15);
$shadow-md: 0 5px 13px rgba($dark, 0.2);
$shadow-lg: 0 10px 25px rgba($dark, 0.15);
$footer: lighten($black, 6%);

// Base font
$font-size-base: 14px;

$font-family-base: "Montserrat", sans-serif;
$font-family-secondary: "Montserrat", sans-serif;

//Color Picker/Switcher
$default: #4863f0;
$green: #6dc77a;
$red: #ff5b69;
$purple: #7952b3;
$skyblue: #32c2ec;
$skobleoff: #0f7173;
$cyan: #00c9a7;
$slateblue: #766df4;

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "danger": $danger,
  "dark": $dark,
  "gray": $gray-100,
  "muted": $muted,
  "light": $light,
  "blue": $primary,
  "footer": $footer,
);
